import React, { PureComponent, createContext } from 'react'

import { getRequest } from '../../api'

export const UserContext = createContext()

export class UserProvider extends PureComponent {
  state = {
    userStatus: 'checking',
    userRole: null,
    userId: null,
    userName: null,
    userEmail: null,
  }

  setUserLoggedOut = () => {
    this.setState({
      userStatus: 'logged-out',
      userRole: null,
      userId: null,
      userName: null,
      userEmail: null,
    })
  }

  checkUserStatus = () => {
    getRequest('/logged')
      .then(response => {
        if (response.data.logged) {
          const {
            role: userRole,
            id: userId,
            name: userName,
            email: userEmail,
          } = response.data.user
          this.setState({
            userStatus: 'logged-in',
            userRole,
            userId,
            userName,
            userEmail,
          })
        } else {
          this.setUserLoggedOut()
        }
      })
      .catch(error => {
        console.error(error)
        this.setUserLoggedOut()
      })
  }

  userIsAdmin = () => {
    const {
      userRole: { id, name },
    } = this.state
    return id === 2 && name === 'admin'
  }

  logoutUser = () => {
    getRequest('/logout')
      .then(response => {
        this.setUserLoggedOut()
      })
      .catch(error => {
        console.error(error)
        this.setUserLoggedOut()
      })
  }

  render() {
    const user = {
      ...this.state,
      checkUserStatus: this.checkUserStatus,
      userIsAdmin: this.userIsAdmin,
      logoutUser: this.logoutUser,
    }
    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export function withUserContext(Component) {
  return function WithUserContextComponent(props) {
    return (
      <UserContext.Consumer>
        {user => <Component {...props} user={user} />}
      </UserContext.Consumer>
    )
  }
}
