import React from 'react'
import { Label } from 'semantic-ui-react'
import { css } from 'emotion'

export const FloatingLabel = ({
  labelValue = ' ',
  labelSize,
  style,
  children,
  ...rest
}) => {
  const labelStyles = css({
    position: 'absolute',
    top: '-1.2em',
    right: '-0.5em',
    animation: 'bounceAlert 0.5s linear 0s 1',
  })

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: 'auto',
        ...style,
      }}
      {...rest}
    >
      {children}
      {labelValue && (
        <Label
          circular
          size={labelSize}
          color="red"
          content={labelValue}
          className={labelStyles}
        />
      )}
    </div>
  )
}
