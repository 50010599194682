import React from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'semantic-ui-react'
import { css } from 'emotion'

import { withUserContext } from '../components/Context'

import logo from '../images/logo-white.png'

const MainHeader = ({ children, user: { logoutUser } }) => {
  const headerStyles = css({
    borderBottom: '1px solid #cccccc',
    marginBottom: '15px',
    padding: '15px 30px 15px 30px',
    display: 'flex',
    flex: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0',
    left: '0',
    right: '0',
    zIndex: '1500',
    background: '#f8b42d',
  })
  const menuStyles = css({
    display: 'flex',
    '& > *': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },
  })

  return (
    <header className={headerStyles}>
      <Link to="/">
        <Image src={logo} width="110px" />
      </Link>
      <nav className={menuStyles}>{children}</nav>
    </header>
  )
}

export default withUserContext(MainHeader)
