import React from 'react'

export const NumberInTable = ({ number, style, ...rest }) => (
  <div
    style={{
      fontFamily: 'Courier New',
      textAlign: 'right',
      ...(number < 0 ? { color: '#B00000' } : {}),
      ...style,
    }}
    {...rest}
  >
    {new Intl.NumberFormat(
      'es-ES',
      number !== parseInt(number, 10) ? { minimumFractionDigits: 2 } : {}
    ).format(number)}
  </div>
)
