import 'core-js/features/array/find'
import 'core-js/features/array/includes'
import 'core-js/features/array/some'
import 'core-js/features/object/values'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-app-polyfill/ie11'

import { UserProvider, MessagesProvider } from './components/Context'
import DocumentTitle from './components/DocumentTitle'
import Root from './screens/Root'

import 'semantic-ui-css/semantic.min.css'
import './index.css'

ReactDOM.render(
  <UserProvider>
    <MessagesProvider>
      <DocumentTitle title={document.title} />
      <Root />
    </MessagesProvider>
  </UserProvider>,
  document.getElementById('root')
)
