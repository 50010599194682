import React, { PureComponent, createContext } from 'react'

const MessagesContext = createContext()

export class MessagesProvider extends PureComponent {
  state = {
    info: [],
    success: [
      // {
      //   datetime: new Date(),
      //   data: [
      //     'Lorem ipsum dolor net to a la guachis comorl pecador de la pradera',
      //     'More lorem ipsum dolor net to a la guachis comorl pecador de la pradera',
      //   ],
      // },
      // {
      //   datetime: new Date(),
      //   data:
      //     'Lorem ipsum dolor net to a la guachis comorl pecador de la pradera',
      // },
    ],
    warning: [],
    error: [],
  }

  setMessages = (messagesArr, type = 'info') => {
    if (!['info', 'success', 'warning', 'error'].includes(type)) {
      console.error(`Tipo de mensaje (${type}) desconocido`)
      return
    }
    this.setState({
      [type]: messagesArr.map(v => ({ datetime: new Date(), data: v })),
    })
  }

  addMessages = (messagesArr, type = 'info') => {
    if (!['info', 'success', 'warning', 'error'].includes(type)) {
      console.error(`Tipo de mensaje (${type}) desconocido`)
      return
    }
    this.setState(prevState => ({
      [type]: [...prevState[type], { datetime: new Date(), data: messagesArr }],
    }))
  }

  removeMessage = (index, type = 'info') => {
    if (!['info', 'success', 'warning', 'error'].includes(type)) {
      console.error(`Tipo de mensaje (${type}) desconocido`)
      return
    }
    this.setState(prevState => ({
      [type]: prevState[type].filter((v, i) => i !== index),
    }))
  }

  resetMessages = (type = 'info') => {
    if (!['info', 'success', 'warning', 'error'].includes(type)) {
      console.error(`Tipo de mensaje (${type}) desconocido`)
      return
    }
    this.setState({ [type]: [] })
  }

  countMessages = () => {
    const { info, success, warning, error } = this.state
    return info.length + success.length + warning.length + error.length
  }

  render() {
    const globalMessages = {
      ...this.state,
      setMessages: this.setMessages,
      addMessages: this.addMessages,
      removeMessage: this.removeMessage,
      resetMessages: this.resetMessages,
      countMessages: this.countMessages,
    }
    return (
      <MessagesContext.Provider value={globalMessages}>
        {this.props.children}
      </MessagesContext.Provider>
    )
  }
}

export function withMessagesContext(Component) {
  return function WithMessagesContextComponent(props) {
    return (
      <MessagesContext.Consumer>
        {globalMessages => (
          <Component {...props} globalMessages={globalMessages} />
        )}
      </MessagesContext.Consumer>
    )
  }
}
