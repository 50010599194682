import React from 'react'
import { Popup } from 'semantic-ui-react'

export const FieldValue = ({ fLabel, fValue, fPrevValue = '', ...rest }) => {
  return (
    <div {...rest}>
      {fLabel && <div style={{ fontSize: '0.9em' }}>{fLabel}</div>}
      <div
        style={{
          fontSize: '1.1em',
          fontWeight: 'bold',
          color: fValue !== fPrevValue ? 'red' : undefined,
        }}
      >
        {fValue !== fPrevValue ? (
          <Popup
            trigger={<div>{fValue || '-'}</div>}
            content={fPrevValue || '-'}
          />
        ) : (
          fValue || '-'
        )}
      </div>
    </div>
  )
}
