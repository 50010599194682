import React from 'react'
import L from 'react-loadable'
import { Loader } from 'semantic-ui-react'

// Component loader personalized to avoid repeating options.
const Loadable = opts =>
  L({
    loading: () => (
      <Loader
        active
        inline="centered"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      />
    ),
    delay: 500,
    ...opts,
  })

export default Loadable
