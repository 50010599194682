import { PureComponent } from 'react'
import { PropTypes } from 'prop-types'

import { PAGETITLESUFFIX } from '../globals'

class DocumentTitle extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
  }
  static defaultProps = {
    title: '',
  }

  componentDidMount() {
    document.title = `${this.props.title}${PAGETITLESUFFIX}`
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.title !== this.props.title)
      document.title = `${this.props.title}${PAGETITLESUFFIX}`
  }

  render() {
    return null
  }
}

export default DocumentTitle
