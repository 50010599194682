import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Dropdown } from 'semantic-ui-react'
import { css } from 'emotion'

import { withUserContext } from '../components/Context'

import { ROUTES } from '../routes'

const MainMenu = ({ user, location: { pathname: route }, style }) => {
  const menuItemActive = css({
    color: '#f8b42d !important',
    background: 'rgba(0, 0, 0, 0.05) !important',
  })

  return (
    <Menu size="large" style={style}>
      <Menu.Item
        as={Link}
        to={ROUTES.DASHBOARD}
        name="dashboard"
        content="Dashboard"
        className={route === ROUTES.DASHBOARD ? menuItemActive : ''}
      />
      {/* <Menu.Item
        as={Link}
        to={ROUTES.OPPORTUNITY_LIST}
        name="opportunity"
        content="Oportunidades"
        className={route.startsWith('/opportunity') ? menuItemActive : ''}
      /> */}
      <Menu.Item
        as={Link}
        to={ROUTES.CLIENT_LIST}
        name="client"
        content="Clientes"
        className={route.startsWith('/client') ? menuItemActive : ''}
      />
      <Dropdown
        item
        text="Ventas"
        className={
          route.startsWith('/invoice') ||
          route.startsWith('/order') ||
          route.startsWith('/remittance')
            ? menuItemActive
            : ''
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={ROUTES.INVOICE_LIST} active={route.startsWith('/invoice')}>
            Facturas
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={ROUTES.ORDER_LIST} active={route.startsWith('/order')}>
            Presupuestos
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={ROUTES.REMITTANCE_LIST}
            active={route.startsWith('/remittance')}
          >
            Remesas
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item
        as={Link}
        to={ROUTES.SERVICE_LIST}
        name="service"
        content="Servicios"
        className={route.startsWith('/service') ? menuItemActive : ''}
      />
      <Menu.Item
        as={Link}
        to={ROUTES.CONTRACT_LIST}
        name="contract"
        content="Renovaciones"
        className={route.startsWith('/contract') ? menuItemActive : ''}
      />
      <Menu.Item
        as={Link}
        to={ROUTES.PRODUCT_LIST}
        name="product"
        content="Productos"
        className={route.startsWith('/product') ? menuItemActive : ''}
      />
      <Menu.Item
        as={Link}
        to={ROUTES.TARIFF_LIST}
        name="tariff"
        content="Tarifas"
        className={route.startsWith('/tariff') ? menuItemActive : ''}
      />
      <Menu.Item
        as={Link}
        to={ROUTES.DOCUMENT_LIST}
        name="document"
        content="Documentos"
        className={route.startsWith('/document') ? menuItemActive : ''}
      />
      {/* <Menu.Item icon="log out" onClick={() => user.logoutUser()} /> */}
    </Menu>
  )
}

export default withUserContext(withRouter(MainMenu))
