import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Header, Icon, Button, Segment, Divider } from 'semantic-ui-react'
import { css } from 'emotion'

import { withUserContext } from './Context'
import { SpaceBetween, Space, FieldValue } from './UI'

import { ROUTE_CLIENT_EDITION } from '../routes'
import { getRequest, putRequest } from '../api'
import { COLOR } from '../globals'

class ClientDataUpdateRequests extends PureComponent {
  static propTypes = {
    onValidationDataChange: PropTypes.func,
  }
  static defaultProps = {
    onValidationDataChange: () => null,
  }

  state = {
    status: 'loading',
    results: [],
    isSaving: [],
    goToClient: null,
    selectedItemIndex: null,
  }

  componentDidMount() {
    this.getClientsDataPendingUpdates()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.results !== this.state.results)
      this.props.onValidationDataChange(this.state.results)
  }

  handleSelectItem = index => {
    this.setState(prevState => ({
      selectedItemIndex: prevState.selectedItemIndex !== index ? index : null,
    }))
  }

  handleValidateClientData = (index, clientId, goToEdit = false) => {
    this.postClientData(index, clientId, goToEdit)
  }

  handleDiscardClientData = (index, clientId) => {
    this.putDiscardData(index, clientId)
  }

  getClientsDataPendingUpdates = () => {
    this.setState({ status: 'loading' })
    getRequest(`/admin/subjects/pending`)
      .then(response => {
        this.setState({
          status: 'ok',
          results: response.data.data,
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({ status: 'error' })
        this.props.user.logoutUser()
      })
  }

  postClientData = (index, clientId, goToEdit) => {
    this.setState(prevState => ({
      isSaving: [...prevState.isSaving, index],
    }))
    getRequest(`/admin/subjects/${clientId}/confirm`)
      .then(response => {
        this.setState(prevState => ({
          results: prevState.results.filter((v, i) => i !== index),
          isSaving: prevState.isSaving.filter(v => v !== index),
          goToClient: goToEdit ? clientId : null,
        }))
      })
      .catch(error => {
        console.error(error)
        //this.setState({ status: 'error' })
        //this.props.user.logoutUser()
      })
  }

  putDiscardData = (index, clientId) => {
    this.setState(prevState => ({
      isSaving: [...prevState.isSaving, index],
    }))
    putRequest(`/admin/subjects/${clientId}`, { id: clientId, validate: '' })
      .then(response => {
        this.setState(prevState => ({
          results: prevState.results.filter((v, i) => i !== index),
          isSaving: prevState.isSaving.filter(v => v !== index),
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    const {
      status,
      results,
      isSaving,
      goToClient,
      selectedItemIndex,
    } = this.state

    if (goToClient)
      return <Redirect to={ROUTE_CLIENT_EDITION.replace(':id', goToClient)} />
    if (status !== 'ok' && results.length === 0) return null

    const segmentStyles = css({
      color: '#000000',
      background: '#ffffff',
    })

    const itemHeaderStyles = css(segmentStyles, {
      transition: 'border-radius 0.8s ease-in-out',
      marginTop: '1rem',
      padding: '1rem',
      border: '1px solid #dddddd',
      borderRadius: '0.4rem',
      ':hover': {
        cursor: 'pointer',
        background: '#eeeeee',
      },
    })

    const itemDataStyles = css(segmentStyles, {
      transition: 'max-height 0.5s cubic-bezier(0, 1, 0, 1)',
      boxSizing: 'content-box',
      borderTop: 'none',
      borderRadius: '0 0 0.4rem 0.4rem',
      maxHeight: '0',
      overflowY: 'hidden',
    })

    const fieldRowStyles = css({
      display: 'flex',
      '& > *': {
        flex: '1 1 auto',
      },
    })

    return (
      <Fragment>
        <Divider />
        <Header
          as="h3"
          inverted
          content="Actualización de datos de cliente"
          subheader="Solicitudes realizadas por clientes"
          style={{ marginBottom: '1.4rem' }}
        />
        {results.map((v, i) => {
          v.billing_profiles = v.billing_profiles.sort((a, b) => a.id - b.id)
          v.contacts = v.contacts.sort((a, b) => a.id - b.id)

          return (
            <Fragment key={v.id + i}>
              <div
                className={css(
                  itemHeaderStyles,
                  selectedItemIndex === i
                    ? {
                        transition: 'border-radius 0.1s linear',
                        borderRadius: '0.4rem 0.4rem 0 0',
                      }
                    : {}
                )}
                onClick={() => this.handleSelectItem(i)}
              >
                <SpaceBetween style={{ alignItems: 'flex-start' }}>
                  <Header
                    as="h4"
                    icon="building"
                    content={v.name}
                    style={{ marginBottom: '0' }}
                  />
                  <Icon
                    name="chevron down"
                    style={{
                      marginTop: '0.2rem',
                      transition: 'all 0.3s ease',
                      ...(selectedItemIndex === i
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}),
                    }}
                  />
                </SpaceBetween>
              </div>
              <div
                className={css(
                  itemDataStyles,
                  selectedItemIndex === i
                    ? {
                        transition: 'max-height 0.5s ease-in-out',
                        maxHeight: '2000px',
                      }
                    : {}
                )}
              >
                <div style={{ padding: '1.4rem' }}>
                  {/* DATOS GENERALES */}
                  <Header
                    as="h4"
                    style={{ color: COLOR }}
                    content="Datos Generales"
                  />
                  <FieldValue
                    fLabel="Email"
                    fValue={v.changes.email}
                    fPrevValue={v.email}
                  />
                  <Space height="0.6rem" />
                  <div className={fieldRowStyles}>
                    <FieldValue
                      fLabel="Tfno. ppal."
                      fValue={v.changes.phone1}
                      fPrevValue={v.phone1}
                    />
                    <FieldValue
                      fLabel="Tfno. sec."
                      fValue={v.changes.phone2}
                      fPrevValue={v.phone2}
                    />
                    <FieldValue
                      fLabel="Fax"
                      fValue={v.changes.fax}
                      fPrevValue={v.fax}
                    />
                  </div>
                  {/* RAZONES SOCIALES */}
                  <Header
                    as="h4"
                    style={{ color: COLOR }}
                    content="Razones sociales"
                  />
                  {v.changes &&
                    v.changes.billing_profiles &&
                    v.changes.billing_profiles
                      .sort((a, b) => a.id - b.id)
                      .map((billingProfile, j) => (
                        <Segment
                          key={billingProfile.id}
                          style={{ fontSize: '0.9rem' }}
                        >
                          <FieldValue
                            style={{ fontSize: '1.1rem' }}
                            fValue={billingProfile.name}
                            fPrevValue={
                              v.billing_profiles[j]
                                ? v.billing_profiles[j].name
                                : ''
                            }
                          />
                          <Space height="0.6rem" />
                          <FieldValue
                            fLabel="CIF / NIF"
                            fValue={billingProfile.tax_code}
                            fPrevValue={
                              v.billing_profiles[j]
                                ? v.billing_profiles[j].tax_code
                                : ''
                            }
                          />
                          <Space height="0.6rem" />
                          <FieldValue
                            fLabel="Dirección"
                            fValue={`${billingProfile.address1} - ${
                              billingProfile.zip
                            } ${billingProfile.town} ${
                              billingProfile.province
                            } (${billingProfile.country})`}
                            fPrevValue={
                              v.billing_profiles[j]
                                ? `${v.billing_profiles[j].address1} - ${
                                    v.billing_profiles[j].zip
                                  } ${v.billing_profiles[j].town} ${
                                    v.billing_profiles[j].province
                                  } (${v.billing_profiles[j].country})`
                                : ''
                            }
                          />
                          <Space height="0.6rem" />
                          <div className={fieldRowStyles}>
                            <FieldValue
                              fLabel="IBAN"
                              fValue={billingProfile.iban_account}
                              fPrevValue={
                                v.billing_profiles[j]
                                  ? v.billing_profiles[j].iban_account
                                  : ''
                              }
                            />
                            <FieldValue
                              fLabel="SWIFT/BIC"
                              fValue={billingProfile.swift}
                              fPrevValue={
                                v.billing_profiles[j]
                                  ? v.billing_profiles[j].swift
                                  : ''
                              }
                            />
                          </div>
                        </Segment>
                      ))}
                  {/* CONTACTOS */}
                  <Header
                    as="h4"
                    style={{ color: COLOR }}
                    content="Contactos"
                  />
                  {v.changes &&
                    v.changes.contacts &&
                    v.changes.contacts
                      .sort((a, b) => a.id - b.id)
                      .map((contact, j) => (
                        <Segment
                          key={contact.id}
                          style={{ fontSize: '0.9rem' }}
                        >
                          <FieldValue
                            style={{ fontSize: '1.1rem' }}
                            fValue={`${contact.name} ${contact.surname}`}
                            fPrevValue={
                              v.contacts[j]
                                ? `${v.contacts[j].name} ${
                                    v.contacts[j].surname
                                  }`
                                : ''
                            }
                          />
                          <Space height="0.6rem" />
                          <FieldValue
                            fLabel="Email"
                            fValue={contact.email}
                            fPrevValue={
                              v.contacts[j] ? v.contacts[j].email : ''
                            }
                          />
                          <div className={fieldRowStyles}>
                            <FieldValue
                              fLabel="Tfno. ppal."
                              fValue={contact.phone1}
                              fPrevValue={
                                v.contacts[j] ? v.contacts[j].phone1 : ''
                              }
                            />
                            <FieldValue
                              fLabel="Tfno. sec."
                              fValue={contact.phone2}
                              fPrevValue={
                                v.contacts[j] ? v.contacts[j].phone2 : ''
                              }
                            />
                          </div>
                        </Segment>
                      ))}
                  <div style={{ textAlign: 'right', marginTop: '1.6rem' }}>
                    <Button
                      compact
                      content="Validar"
                      onClick={() => this.handleValidateClientData(i, v.id)}
                      loading={isSaving.includes(i)}
                    />
                    <Button
                      compact
                      content="Validar y Editar"
                      onClick={() =>
                        this.handleValidateClientData(i, v.id, true)
                      }
                      loading={isSaving.includes(i)}
                    />
                    <Button
                      compact
                      content="Descartar"
                      onClick={() => this.handleDiscardClientData(i, v.id)}
                      loading={isSaving.includes(i)}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          )
        })}
      </Fragment>
    )
  }
}

export default withUserContext(ClientDataUpdateRequests)
