import React, { PureComponent } from 'react'
import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { css } from 'emotion'

import LoginForm from '@bit/rigobauer.iteisa-components.semantic-ui.login-form'
import DocumentTitle from '../components/DocumentTitle'
import Logo from '../components/Logo'
import { Space } from '../components/UI'

import { APIBASEURL, APIREQUESTOPTIONS, APIURL } from '../globals'
import { ROUTES } from '../routes'

class ScreensLogin extends PureComponent {
  componentDidMount() {
    document.getElementsByName('login')[0].focus()
  }

  loginResponseValidation = response => response.data.login === true

  handleLoginSuccess = response => {
    window.location.href = '/'
  }

  mainStyles = css({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  })
  loginStyles = css({
    width: '90%',
    padding: '2rem !important',
    animation: 'fadeInOpacity 1s ease 0s 1',
    '@media (min-width: 576px)': {
      width: '450px',
      padding: '4rem !important',
    },
  })
  logoStyles = css({
    width: '60%',
    '@media (min-width: 576px)': {
      width: '180px',
    },
  })

  ulStyles = css({
    whiteSpace: 'nowrap',
    width: '100%',
    maxWidth:'450px',
  })

  listStyles = css({
    whiteSpace: 'wrap',
    width: '100%',
    marginBottom:'-5%',
    
    'li' :{
      verticalAlign: 'top',
      display: 'inline-block',
      fontSize: '0.9em',
      padding: '0.5em',
      whiteSpace: 'nowrap',
      'a': {color: 'gray'},
    }
  })

  render() {
    return (
      <main className={this.mainStyles}>
        <DocumentTitle title="Acceso al área de gestión" />
        <Segment className={this.loginStyles}>
          <div style={{ fill: '#f8b42d', textAlign: 'center' }}>
            <Logo className={this.logoStyles} />
          </div>
          <Space height="2rem" />
          <LoginForm
            urlLogin={`${APIBASEURL}/login`}
            apiRequestOptions={APIREQUESTOPTIONS}
            loginPlaceholder="Usuario"
            loginUrlVar="email"
            pwdPlaceholder="Contraseña"
            pwdUrlVar="password"
            errorMessage={
              'Mmmm... Algo raro ha sucedido. Será mejor que lo vuelvas a intentar.'
            }
            loginResponseValidation={this.loginResponseValidation}
            handleLoginSuccess={this.handleLoginSuccess}
          />
            
            <ul className={this.listStyles}>
            <li>
              {/*<Link to={ROUTES.LEGAL_WARNING}>
              Información Legal
              </Link>*/}
              <a href="https://www.zitelia.com/aviso-legal/" target="_blank" rel="noopener noreferrer">
                Información Legal
              </a>
            </li>
            <li>
              {/*<Link to={ROUTES.PRIVACY_POLICY}>
              Política de privacidad
              </Link>*/}
              <a href="https://www.zitelia.com/proteccion-de-datos/" target="_blank" rel="noopener noreferrer">
                Política de privacidad
              </a>
            </li>
            <li>
            <Link to={ROUTES.COOKIE_POLICY}>
              Política de cookies
            </Link>
            </li>
          {/*  <li>
            <Link to={ROUTES.TERMS_AND_CONDITIONS}>
              Términos y condiciones
            </Link>
            </li>
          */
          }
          <li>
            <a href={APIURL+"/file/contrato-prestacion-servicios-zitelia.pdf"} target="_blank" rel="noopener noreferrer">
              Condiciones generales
            </a>
          </li>
          </ul>
        </Segment>

      </main>
    )
  }
}

export default ScreensLogin
