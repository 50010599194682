import React from 'react'
import { css } from 'emotion'

export const SpaceBetween = ({ wrapper, children, ...rest }) => {
  const Wrapper = wrapper || 'div'
  const className = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
  return (
    <Wrapper className={className} {...rest}>
      {children}
    </Wrapper>
  )
}
