import React, { PureComponent } from 'react'

import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { css } from 'emotion'

import DocumentTitle from '../../components/DocumentTitle'
import Logo from '../../components/Logo'
import { Space } from '../../components/UI'

class ScreensCookiesPolicy extends PureComponent {


  mainStyles = css({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    //height: '100%',
  })
  loginStyles = css({
    width: '90%',
    padding: '2rem !important',
    animation: 'fadeInOpacity 1s ease 0s 1',
    '@media (min-width: 1280px)': {
      padding: '6rem !important',
      width: '1200px',
    },
    
  })
  logoStyles = css({
    maxWidth: '400px',
    width: '60%',
    '@media (min-width: 576px)': {
      width: '180px',
    },
  })

  render() {
    return (
      <main className={this.mainStyles}>
        <DocumentTitle title="Acceso al área de gestión" />
        <Segment className={this.loginStyles}>
          <div style={{width:'100%'}}>
          <div style={{ fill: '#f8b42d', textAlign: 'center', maxWidth:'400px', margin:'auto'}}>
            <Link to={'/'}>
              <Logo className={this.logoStyles} />
            </Link>
          </div>
          </div>
          <Space height="2rem" />
          <h1>POLÍTICA DE COOKIES</h1>
        <br/>
        <p>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L. garantiza a los usuarios que su página web cumple con los niveles de seguridad establecidos en la normativa relativa a la protección de datos, y que hace uso de todos los medios técnicos a su alcance para impedir cualquier alteración, pérdida, acceso sin autorización o mala utilización de esos datos. </p>
        <p>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L. pone en conocimiento del Usuario el empleo de cookies en la presente página web. Las cookies son archivos temporales que, a través de su inserción automática en el equipo del Usuario, permiten ofrecer un contenido personalizado al mismo. No obstante lo anterior, se informa de que las cookies utilizadas se asocian de forma anónima tanto con el Usuario como con su equipo, de tal forma que no se proporciona referencia alguna en relación a los datos personales del Usuario. En cualquier caso, el Usuario podrá modificar la configuración de su ordenador, a través de la barra de herramientas de su navegador de Internet, desactivando o eliminado todas las cookies si lo estima conveniente.</p>

        </Segment>
      </main>
    )

  }
}

export default ScreensCookiesPolicy
