import React, { Component, Fragment } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Divider, Header, Menu, Icon } from 'semantic-ui-react'
import styled from 'react-emotion'

import { withUserContext, withMessagesContext } from '../components/Context'
import Loadable from '../components/Loadable'
import MainHeader from '../components/MainHeader'
import MainMenu from '../components/MainMenu'
import Messages from '../components/Messages'
import GlobalSearch from '../components/GlobalSearch'
import ClientDataUpdateRequests from '../components/ClientDataUpdateRequests'
import ScreensLogin from './Login'
import { Space, FloatingLabel } from '../components/UI'

import { ROUTES } from '../routes'
import ScreensPrivacyPolicy from './Static/PrivacyPolicy'
import ScreensCookiesPolicy from './Static/CookiesPolicy'
import ScreensTermsAndCondition from './Static/TermAndCondition'
import ScreensLegalWarning from './Static/LegalWarning'


// Code Splitting: Prepare component to be loaded asynchronously when required.
const AsyncScreensDashboard = Loadable({ loader: () => import('./Dashboard') })
const AsyncScreensErrorPage = Loadable({ loader: () => import('./ErrorPage') })
const AsyncScreensList = Loadable({ loader: () => import('./List') })
const AsyncScreensDocumentList = Loadable({
  loader: () => import('./Document/List'),
})
const AsyncScreensForm = Loadable({ loader: () => import('./Form') })
const AsyncScreensClientForm = Loadable({
  loader: () => import('./Client/Form'),
})
const AsyncScreensClientViewAdmin = Loadable({
  loader: () => import('./Client/AdminView'),
})
const AsyncScreensClientViewUser = Loadable({
  loader: () => import('./Client/UserView'),
})
const AsyncScreensProductView = Loadable({
  loader: () => import('./Product/View'),
})
const AsyncScreensOrderView = Loadable({
  loader: () => import('./Order/View'),
})
const AsyncScreensInvoiceView = Loadable({
  loader: () => import('./Invoice/View'),
})
const AsyncScreensServiceView = Loadable({
  loader: () => import('./Service/View'),
})
const AsyncScreensServiceRenewals = Loadable({
  loader: () => import('./Service/Renewals'),
})
const AsyncScreensPayment = Loadable({
  loader: () => import('./Service/Payment'),
})
const AsyncScreensPaymentResult = Loadable({
  loader: () => import('./Service/PaymentResult'),
})

const ParentContainer = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  min-height: 100%;
`
const Main = styled('div')`
  flex: 0 0 auto;
  transition: width 0.3s ease-out;
  width: ${props =>
    props.isSideBarVisible
      ? '70%'
      : props.userIsAdmin
      ? 'calc(100% - 50px)'
      : '100%'};
  @media (min-width: 1485px) {
    width: ${props => (props.userIsAdmin ? '70%' : undefined)};
  }
`
const NotificationsArea = styled('div')`
  flex: 0 0 auto;
  width: 30%;
  color: #ffffff;
  background: #222222;
  position: relative;
  & .na-container {
    position: fixed;
    overflow-y: scroll;
    overscroll-behavior: contain;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: stretch;
  }
`
const NotificationsAreaIconBar = styled('div')`
  & .na-icon {
    width: 50px;
    margin: 2rem 1rem 2rem 1rem;
  }
  @media (max-width: 1485px) {
    cursor: pointer;
  }
`
const NotificationsAreaContent = styled('div')`
  width: 100%;
  padding: 2rem 2rem 2rem 0;
`

class Root extends Component {
  state = {
    isSideBarVisible: false,
    nClientValidations: 0,
  }

  componentDidMount() {
    this.props.user.checkUserStatus()
  }

  handleToggleSideBar = () => {
    this.setState(prevState => ({
      isSideBarVisible: !prevState.isSideBarVisible,
    }))
  }

  handleValidationDataChange = clientValidationData => {
    this.setState({ nClientValidations: clientValidationData.length })
  }

  render() {
    const {
      user,
      globalMessages: { countMessages },
    } = this.props
    const { isSideBarVisible, nClientValidations } = this.state

    if (user.userStatus === 'checking') return null
    if (user.userStatus !== 'logged-in')
      return (
        <BrowserRouter>
          <Switch>
            <Route
              path={'/renovaciones/:id/:hash'}
              render={props => (
                <Fragment>
                  <MainHeader />
                  <AsyncScreensServiceRenewals {...props} />
                </Fragment>
              )}
            />
            <Route
              path={'/renovar/:id/:hash'}
              render={props => (
                <Fragment>
                  <MainHeader />
                  <AsyncScreensServiceRenewals {...props} />
                </Fragment>
              )}
            />
            <Route
              path={'/pagar/presupuesto/:number/:amount/:hash'}
              render={props => (
                <Fragment>
                  <MainHeader />
                  <AsyncScreensPayment type="orders" {...props} />
                </Fragment>
              )}
            />
            <Route
              path={'/pagar/factura/:number/:amount/:hash'}
              render={props => (
                <Fragment>
                  <MainHeader />
                  <AsyncScreensPayment type="bills" {...props} />
                </Fragment>
              )}
            />
            <Route
              path={'/pagar/:number/:amount/:hash'}
              render={props => (
                <Fragment>
                  <MainHeader />
                  <AsyncScreensPayment type="orders" {...props} />
                </Fragment>
              )}
            />
            <Route
              path={'/pago/correcto'}
              render={props => (
                <Fragment>
                  <MainHeader />
                  <AsyncScreensPaymentResult result="ok" {...props} />
                </Fragment>
              )}
            />
            <Route
              path={'/pago/error'}
              render={props => (
                <Fragment>
                  <MainHeader />
                  <AsyncScreensPaymentResult result="error" {...props} />
                </Fragment>
              )}
            />
            <Route path={ROUTES.LEGAL_WARNING}
              component={ScreensLegalWarning} />
            <Route path={ROUTES.PRIVACY_POLICY}
              component={ScreensPrivacyPolicy} />
            <Route path={ROUTES.COOKIE_POLICY}
              component={ScreensCookiesPolicy} />
            <Route path={ROUTES.TERMS_AND_CONDITIONS}
              component={ScreensTermsAndCondition} />
            <Route component={ScreensLogin} />

            
          </Switch>
        </BrowserRouter>
      )

    const nNotifications = countMessages() + nClientValidations

    return (
      <BrowserRouter>
        <ParentContainer>
          <Main
            isSideBarVisible={isSideBarVisible}
            userIsAdmin={user.userIsAdmin()}
          >
            <MainHeader>
              {user.userIsAdmin() && (
                <MainMenu style={{ marginRight: '1.2rem' }} />
              )}
              <Menu size="large">
                <Menu.Item
                  icon="log out"
                  content="Salir"
                  onClick={() => user.logoutUser()}
                />
              </Menu>
            </MainHeader>
            {user.userIsAdmin() ? (
              <Fragment>
                <GlobalSearch />
                <Divider />
                <Switch>
                  <Route
                    path={'/renovaciones/:id/:hash'}
                    render={props => <AsyncScreensServiceRenewals {...props} />}
                  />
                  <Route
                    path={'/renovar/:id/:hash'}
                    render={props => <AsyncScreensServiceRenewals {...props} />}
                  />
                  <Route
                    path={'/pagar/presupuesto/:number/:amount/:hash'}
                    render={props => (
                      <AsyncScreensPayment type="orders" {...props} />
                    )}
                  />
                  <Route
                    path={'/pagar/factura/:number/:amount/:hash'}
                    render={props => (
                      <AsyncScreensPayment type="bills" {...props} />
                    )}
                  />
                  <Route
                    path={'/pagar/:number/:amount/:hash'}
                    render={props => (
                      <AsyncScreensPayment type="orders" {...props} />
                    )}
                  />
                  <Route
                    path={'/pago/correcto'}
                    render={props => (
                      <AsyncScreensPaymentResult result="ok" {...props} />
                    )}
                  />
                  <Route
                    path={'/pago/error'}
                    render={props => (
                      <AsyncScreensPaymentResult result="error" {...props} />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.DASHBOARD}
                    component={AsyncScreensDashboard}
                  />
                  <Route
                    path={ROUTES.OPPORTUNITY_LIST}
                    render={props => (
                      <AsyncScreensList section="opportunity" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.CLIENT_LIST}
                    render={props => (
                      <AsyncScreensList section="client" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.CLIENT_EDITION}
                    component={AsyncScreensClientForm}
                  />
                  <Route
                    path={ROUTES.CLIENT_CREATION}
                    component={AsyncScreensClientForm}
                  />
                  <Route
                    path={ROUTES.CLIENT_VIEW_INFO}
                    render={props => (
                      <AsyncScreensClientViewAdmin
                        subsection="info"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={ROUTES.CLIENT_VIEW_SERVICES}
                    render={props => (
                      <AsyncScreensClientViewAdmin
                        subsection="services"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={ROUTES.CLIENT_VIEW_ORDERS}
                    render={props => (
                      <AsyncScreensClientViewAdmin
                        subsection="orders"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={ROUTES.CLIENT_VIEW_INVOICES}
                    render={props => (
                      <AsyncScreensClientViewAdmin
                        subsection="invoices"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={ROUTES.CLIENT_VIEW}
                    component={AsyncScreensClientViewAdmin}
                  />
                  <Route
                    path={ROUTES.INVOICE_LIST}
                    render={props => (
                      <AsyncScreensList section="invoice" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.INVOICE_EDITION}
                    render={props => (
                      <AsyncScreensForm section="invoice" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.INVOICE_CREATION}
                    render={props => (
                      <AsyncScreensForm section="invoice" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.INVOICE_BUSINESS_CREATION}
                    render={props => (
                      <AsyncScreensForm section="invoice" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.INVOICE_VIEW}
                    component={AsyncScreensInvoiceView}
                  />
                  <Route
                    path={ROUTES.ORDER_LIST}
                    render={props => (
                      <AsyncScreensList section="order" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.ORDER_EDITION}
                    render={props => (
                      <AsyncScreensForm section="order" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.ORDER_CREATION}
                    render={props => (
                      <AsyncScreensForm section="order" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.ORDER_BUSINESS_CREATION}
                    render={props => (
                      <AsyncScreensForm section="order" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.ORDER_VIEW}
                    component={AsyncScreensOrderView}
                  />
                  <Route
                    path={ROUTES.REMITTANCE_LIST}
                    render={props => (
                      <AsyncScreensList section="remittance" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.REMITTANCE_EDITION}
                    component={AsyncScreensDashboard}
                  />
                  <Route
                    path={ROUTES.REMITTANCE_CREATION}
                    component={AsyncScreensDashboard}
                  />
                  <Route
                    path={ROUTES.PRODUCT_LIST}
                    render={props => (
                      <AsyncScreensList section="product" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.PRODUCT_EDITION}
                    component={AsyncScreensDashboard}
                  />
                  <Route
                    path={ROUTES.PRODUCT_CREATION}
                    component={AsyncScreensDashboard}
                  />
                  <Route
                    path={ROUTES.PRODUCT_VIEW}
                    component={AsyncScreensProductView}
                  />
                  <Route
                    path={ROUTES.SERVICE_LIST}
                    render={props => (
                      <AsyncScreensList section="service" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.SERVICE_EDITION}
                    render={props => (
                      <AsyncScreensForm section="service" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.SERVICE_CREATION}
                    render={props => (
                      <AsyncScreensForm section="service" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.SERVICE_BUSINESS_CREATION}
                    render={props => (
                      <AsyncScreensForm section="service" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.SERVICE_VIEW}
                    component={AsyncScreensServiceView}
                  />
                  <Route
                    path={ROUTES.CONTRACT_LIST}
                    render={props => (
                      <AsyncScreensList section="contract" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.CONTRACT_EDITION}
                    render={props => (
                      <AsyncScreensForm section="contract" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.CONTRACT_CREATION}
                    render={props => (
                      <AsyncScreensForm section="contract" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.CONTRACT_BUSINESS_CREATION}
                    render={props => (
                      <AsyncScreensForm section="contract" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.TARIFF_LIST}
                    render={props => (
                      <AsyncScreensList section="tariff" {...props} />
                    )}
                  />
                  <Route
                    path={ROUTES.TARIFF_EDITION}
                    component={AsyncScreensDashboard}
                  />
                  <Route
                    path={ROUTES.TARIFF_CREATION}
                    component={AsyncScreensDashboard}
                  />
                  <Route
                    path={ROUTES.DOCUMENT_LIST}
                    component={AsyncScreensDocumentList}
                  />
                  <Route
                    path={ROUTES.DOCUMENT_EDITION}
                    component={AsyncScreensDashboard}
                  />
                  <Route
                    path={ROUTES.DOCUMENT_CREATION}
                    component={AsyncScreensDashboard}
                  />
                  <Route component={AsyncScreensErrorPage} />
                </Switch>
              </Fragment>
            ) : user.userRole.name === 'client' ? (
              <Switch>
                <Route
                  path={'/renovaciones/:id/:hash'}
                  render={props => <AsyncScreensServiceRenewals {...props} />}
                />
                <Route
                  path={'/renovar/:id/:hash'}
                  render={props => <AsyncScreensServiceRenewals {...props} />}
                />
                <Route
                  path={'/pagar/presupuesto/:number/:amount/:hash'}
                  render={props => (
                    <AsyncScreensPayment type="orders" {...props} />
                  )}
                />
                <Route
                  path={'/pagar/factura/:number/:amount/:hash'}
                  render={props => (
                    <AsyncScreensPayment type="bills" {...props} />
                  )}
                />
                <Route
                  path={'/pagar/:number/:amount/:hash'}
                  render={props => (
                    <AsyncScreensPayment type="orders" {...props} />
                  )}
                />
                <Route
                  path={'/pago/correcto'}
                  render={props => (
                    <AsyncScreensPaymentResult result="ok" {...props} />
                  )}
                />
                <Route
                  path={'/pago/error'}
                  render={props => (
                    <AsyncScreensPaymentResult result="error" {...props} />
                  )}
                />
                <Route
                  path={ROUTES.CLIENT_EDITION.replace(':id', user.userId)}
                  render={props => (
                    <AsyncScreensClientForm clientId={user.userId} {...props} />
                  )}
                />
                <Route component={AsyncScreensClientViewUser} />
              </Switch>
            ) : null}
          </Main>
          {user.userIsAdmin() && (
            <NotificationsArea>
              <div className="na-container">
                <NotificationsAreaIconBar onClick={this.handleToggleSideBar}>
                  <FloatingLabel
                    key={`nNotifications${nNotifications}`}
                    labelValue={nNotifications || ''}
                    labelSize="tiny"
                    className="na-icon"
                  >
                    <Icon name="list ul" size="large" />
                  </FloatingLabel>
                </NotificationsAreaIconBar>
                <NotificationsAreaContent>
                  <Header
                    as="h2"
                    inverted
                    content="Notificaciones"
                    style={{ marginBottom: '1rem' }}
                  />
                  <Divider />
                  <Messages />
                  <ClientDataUpdateRequests
                    onValidationDataChange={this.handleValidationDataChange}
                  />
                  <Space height="3rem" />
                </NotificationsAreaContent>
              </div>
            </NotificationsArea>
          )}
        </ParentContainer>
      </BrowserRouter>
    )
  }
}

export default withUserContext(withMessagesContext(Root))
