import React, { PureComponent } from 'react'

import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { css } from 'emotion'

import DocumentTitle from '../../components/DocumentTitle'
import Logo from '../../components/Logo'
import { Space } from '../../components/UI'

class ScreensTermsAndCondition extends PureComponent {


  mainStyles = css({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    //height: '100%',
  })
  loginStyles = css({
    width: '90%',
    padding: '2rem !important',
    animation: 'fadeInOpacity 1s ease 0s 1',
    '@media (min-width: 1280px)': {
      padding: '6rem !important',
      width: '1200px',
    },

  })
  logoStyles = css({
    maxWidth: '400px',
    width: '60%',
    '@media (min-width: 576px)': {
      width: '180px',
    },
  })

  render() {
    return (
      <main className={this.mainStyles}>
        <DocumentTitle title="Acceso al área de gestión" />
        <Segment className={this.loginStyles}>
          <div style={{ width: '100%' }}>
            <div style={{ fill: '#f8b42d', textAlign: 'center', maxWidth: '400px', margin: 'auto' }}>
              <Link to={'/'}>
                <Logo className={this.logoStyles} />
              </Link>
            </div>
          </div>
          <Space height="2rem" />
          <h1>TÉRMINOS Y CONDICIONES</h1>
          <br />
          <p><strong>Métodos de pago</strong></p>
          <p>Este portal web ofrece a los usuarios registrados las siguientes formas de pago de los servicios contratados en el mismo:</p>
          <p>•	PAGO CON TARJETA: sistema de pago online con tarjeta de crédito a través de pasarela de pago segura.</p>
          <p>•	TRANSFERENCIA BANCARIA: sistema de pago mediante transferencia online o física a la cuenta especificada por la plataforma.</p>
          <p>•	CARGO EN CUENTA: sistema de pago domiciliado a la cuenta bancaria indicada por el usuario dentro de la plataforma.</p>
          <br />
          <p><strong>Política de Devoluciones</strong></p>
          <p>La gestión de devoluciones sobre servicios contratados se hará siempre sobre ajustes en próximas contrataciones o renovaciones de servicio.</p>
          <p><strong>Política de Cancelaciones</strong></p>
          <p>La gestión de cancelaciones de servicios contratados deberá ser notificada por el usuario por los métodos disponibles en la propia plataforma.</p>

        </Segment>
      </main>
    )

  }
}

export default ScreensTermsAndCondition
