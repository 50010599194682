export const ROUTE_LOGIN = '/login'
export const ROUTE_LEGAL_WARNING = '/informacion-legal'
export const ROUTE_PRIVACY_POLICY = '/politica-privacidad'
export const ROUTE_COOKIE_POLICY = '/politica-de-cookies'
export const ROUTE_TERMS_AND_CONDITIONS = '/terminos-y-condiciones'


export const ROUTE_DASHBOARD = '/'

export const ROUTE_OPPORTUNITY_LIST = '/opportunity/list'

export const ROUTE_CLIENT_LIST = '/client/list'
export const ROUTE_CLIENT_CREATION = '/client/form'
export const ROUTE_CLIENT_EDITION = '/client/form/:id'
export const ROUTE_CLIENT_VIEW = '/client/:id'
export const ROUTE_CLIENT_VIEW_INFO = '/client/:id/info'
export const ROUTE_CLIENT_VIEW_SERVICES = '/client/:id/services'
export const ROUTE_CLIENT_VIEW_ORDERS = '/client/:id/orders'
export const ROUTE_CLIENT_VIEW_INVOICES = '/client/:id/invoices'

export const ROUTE_INVOICE_LIST = '/invoice/list'
export const ROUTE_INVOICE_CREATION = '/invoice/form'
export const ROUTE_INVOICE_EDITION = '/invoice/form/:id'
export const ROUTE_INVOICE_BUSINESS_CREATION = '/invoice/business/:idBusiness'
export const ROUTE_INVOICE_VIEW = '/invoice/:id'

export const ROUTE_ORDER_LIST = '/order/list'
export const ROUTE_ORDER_CREATION = '/order/form'
export const ROUTE_ORDER_EDITION = '/order/form/:id'
export const ROUTE_ORDER_BUSINESS_CREATION = '/order/business/:idBusiness'
export const ROUTE_ORDER_VIEW = '/order/:id'

export const ROUTE_REMITTANCE_LIST = '/remittance/list'
export const ROUTE_REMITTANCE_CREATION = '/remittance/form'
export const ROUTE_REMITTANCE_EDITION = '/remittance/form/:id'
export const ROUTE_REMITTANCE_VIEW = '/remittance/:id'

export const ROUTE_PRODUCT_LIST = '/product/list'
export const ROUTE_PRODUCT_CREATION = '/product/form'
export const ROUTE_PRODUCT_EDITION = '/product/form/:id'
export const ROUTE_PRODUCT_VIEW = '/product/:id'

export const ROUTE_SERVICE_LIST = '/service/list'
export const ROUTE_SERVICE_CREATION = '/service/form'
export const ROUTE_SERVICE_EDITION = '/service/form/:id'
export const ROUTE_SERVICE_BUSINESS_CREATION = '/service/business/:idClient'
export const ROUTE_SERVICE_VIEW = '/service/:id'

export const ROUTE_CONTRACT_LIST = '/contract/list'
export const ROUTE_CONTRACT_CREATION = '/contract/form'
export const ROUTE_CONTRACT_EDITION = '/contract/form/:id'
export const ROUTE_CONTRACT_BUSINESS_CREATION =
  '/contract/business/:idClient/:idProductCategory/:idService'

export const ROUTE_TARIFF_LIST = '/tariff/list'
export const ROUTE_TARIFF_CREATION = '/tariff/form'
export const ROUTE_TARIFF_EDITION = '/tariff/form/:id'
export const ROUTE_TARIFF_VIEW = '/tariff/:id'

export const ROUTE_DOCUMENT_LIST = '/document/list'

export const ROUTES = {
  LOGIN: ROUTE_LOGIN,
  LEGAL_WARNING: ROUTE_LEGAL_WARNING,
  PRIVACY_POLICY: ROUTE_PRIVACY_POLICY,
  COOKIE_POLICY: ROUTE_COOKIE_POLICY,
  TERMS_AND_CONDITIONS: ROUTE_TERMS_AND_CONDITIONS,
  DASHBOARD: ROUTE_DASHBOARD,
  OPPORTUNITY_LIST: ROUTE_OPPORTUNITY_LIST,
  CLIENT_LIST: ROUTE_CLIENT_LIST,
  CLIENT_CREATION: ROUTE_CLIENT_CREATION,
  CLIENT_EDITION: ROUTE_CLIENT_EDITION,
  CLIENT_VIEW: ROUTE_CLIENT_VIEW,
  CLIENT_VIEW_INFO: ROUTE_CLIENT_VIEW_INFO,
  CLIENT_VIEW_SERVICES: ROUTE_CLIENT_VIEW_SERVICES,
  CLIENT_VIEW_ORDERS: ROUTE_CLIENT_VIEW_ORDERS,
  CLIENT_VIEW_INVOICES: ROUTE_CLIENT_VIEW_INVOICES,
  INVOICE_LIST: ROUTE_INVOICE_LIST,
  INVOICE_CREATION: ROUTE_INVOICE_CREATION,
  INVOICE_EDITION: ROUTE_INVOICE_EDITION,
  INVOICE_BUSINESS_CREATION: ROUTE_INVOICE_BUSINESS_CREATION,
  INVOICE_VIEW: ROUTE_INVOICE_VIEW,
  ORDER_LIST: ROUTE_ORDER_LIST,
  ORDER_CREATION: ROUTE_ORDER_CREATION,
  ORDER_EDITION: ROUTE_ORDER_EDITION,
  ORDER_BUSINESS_CREATION: ROUTE_ORDER_BUSINESS_CREATION,
  ORDER_VIEW: ROUTE_ORDER_VIEW,
  REMITTANCE_LIST: ROUTE_REMITTANCE_LIST,
  REMITTANCE_CREATION: ROUTE_REMITTANCE_CREATION,
  REMITTANCE_EDITION: ROUTE_REMITTANCE_EDITION,
  REMITTANCE_VIEW: ROUTE_REMITTANCE_VIEW,
  PRODUCT_LIST: ROUTE_PRODUCT_LIST,
  PRODUCT_CREATION: ROUTE_PRODUCT_CREATION,
  PRODUCT_EDITION: ROUTE_PRODUCT_EDITION,
  PRODUCT_VIEW: ROUTE_PRODUCT_VIEW,
  SERVICE_LIST: ROUTE_SERVICE_LIST,
  SERVICE_CREATION: ROUTE_SERVICE_CREATION,
  SERVICE_EDITION: ROUTE_SERVICE_EDITION,
  SERVICE_BUSINESS_CREATION: ROUTE_SERVICE_BUSINESS_CREATION,
  SERVICE_VIEW: ROUTE_SERVICE_VIEW,
  CONTRACT_LIST: ROUTE_CONTRACT_LIST,
  CONTRACT_CREATION: ROUTE_CONTRACT_CREATION,
  CONTRACT_EDITION: ROUTE_CONTRACT_EDITION,
  CONTRACT_BUSINESS_CREATION: ROUTE_CONTRACT_BUSINESS_CREATION,
  TARIFF_LIST: ROUTE_TARIFF_LIST,
  TARIFF_CREATION: ROUTE_TARIFF_CREATION,
  TARIFF_EDITION: ROUTE_TARIFF_EDITION,
  TARIFF_VIEW: ROUTE_TARIFF_VIEW,
  DOCUMENT_LIST: ROUTE_DOCUMENT_LIST,
}
