import React, { PureComponent } from 'react'

import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { css } from 'emotion'

import DocumentTitle from '../../components/DocumentTitle'
import Logo from '../../components/Logo'
import { Space } from '../../components/UI'

class ScreensPrivacyPolicy extends PureComponent {


  mainStyles = css({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    //height: '100%',
  })
  loginStyles = css({
    width: '90%',
    padding: '2rem !important',
    animation: 'fadeInOpacity 1s ease 0s 1',
    '@media (min-width: 1280px)': {
      padding: '6rem !important',
      width: '1200px',
    },

  })
  logoStyles = css({
    maxWidth: '400px',
    width: '60%',
    '@media (min-width: 576px)': {
      width: '180px',
    },
  })

  tableStyles = css({
    'table th': {
      border: '1px solid #dcdcdc',
      background: '#f6f6f6',
      padding: '1rem',
      textAlign: 'left',
      fontSize: '0.9rem',
      fontWeight: '500'
    }
  })

  sectionStyles = css({
    'p': {
      lineHeight: '2rem',
      padding: '0.5rem',
    }
  })

  render() {
    return (
      <main className={this.mainStyles}>
        <DocumentTitle title="Acceso al área de gestión" />
        <Segment className={this.loginStyles}>
          <div style={{ width: '100%' }}>
            <div style={{ fill: '#f8b42d', textAlign: 'center', maxWidth: '400px', margin: 'auto' }}>
              <Link to={'/'}>
                <Logo className={this.logoStyles} />
              </Link>
            </div>
          </div>
          <Space height="2rem" />
          <h1>POLÍTICA DE PRIVACIDAD</h1>
          <section id="content" className={this.sectionStyles} >

            <p><strong><u>PROTECCIÓN DE DATOS PERSONALES</u></strong></p>
            <p><strong>ZITELIA</strong> garantiza la seguridad y confidencialidad de los datos facilitados por cada USUARIO, cumpliendo con lo dispuesto en el vigente Reglamento (UE) 2016/679, de Protección de Datos de Carácter Personal y conforme al compromiso que adquiere al aplicar su Política de Privacidad.</p>
            <ol>
              <li><strong> ¿Quién es el Responsable de tratamiento de sus datos?</strong></li>
            </ol>
            <p>El responsable del tratamiento de datos referentes a los distintos procesos con respecto la gestión de nuestros usuarios, clientes o proveedores es&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong>, con domicilio social en Avda. de los Castros 33, Urb. Los Chopos, Portal 1 – Bajo 2, 39005, Santander, Cantabria y CIF B39776323.</p>
            <p>A efectos de nuestra política de protección de datos el teléfono de contacto es 942760866 y el correo electrónico de contacto es&nbsp;zitelia@zitelia.com</p>
            <ol start="2">
              <li><strong> ¿Qué tipo de datos tenemos sobre su persona y cómo los hemos obtenido?</strong></li>
            </ol>
            <p>Las categorías de datos personales que <strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> trata sobre sus clientes y proveedores son:</p>
            <ul>
              <li>Datos de identificación</li>
              <li>Direcciones postales o electrónicas</li>
              <li>Información comercial</li>
              <li>Datos económicos y de transacciones</li>
            </ul>
            <p>En ningún caso tratamos datos especialmente protegidos.</p>
            <p>Todos los datos arriba mencionados los hemos obtenido o directamente de Usted mediante el envío de un formulario de contacto o la presentación de una propuesta comercial, contrato, etc. o mediante su empresa al facilitarnos los datos de identificación y demás información necesaria para llevar a cabo el objeto de la relación contractual entre las partes. Será una obligación suya o de su empresa facilitarnos los datos actualizados en caso de modificación.</p>
            <ol start="3">
              <li><strong> ¿Con qué finalidad tratamos sus datos?</strong></li>
            </ol>
            <p>En&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong>&nbsp;tratamos los datos que nos facilitan las personas interesadas con el fin de gestionar distintas actividades derivadas de procedimientos específicos realizados en materia de ventas, servicio post venta, gestión de proveedores, calidad de servicios, etc. De esta manera, utilizaremos sus datos para llevar al cabo algunas de las siguientes acciones:</p>
            <ul>
              <li>Enviar de la información que nos soliciten mediante el formulario de contacto de nuestra página web o cualquier otro medio de contacto con nuestra empresa,</li>
              <li>Facilitar tanto a los clientes potenciales como a nuestros clientes, ofertas de productos y servicios de su interés,</li>
              <li>Llevar a cabo la gestión administrativa, fiscal y contable de nuestros clientes y/o proveedores,</li>
              <li>Llevar a cabo encuestas de satisfacción, estudios de mercado, con el fin de poder ofrecerle las ofertas más adecuadas y una calidad optimizada de servicio, etc.</li>
            </ul>
            <p>No vamos a elaborar perfiles comerciales en base de la información facilitada y en consecuencia tampoco tomaremos decisiones automatizadas sobre Usted en base de un perfil comercial.</p>
            <ol start="4">
              <li><strong> ¿Por cuánto tiempo conservaremos sus datos?</strong></li>
            </ol>
            <p>Los datos personales relativos a personas físicas vinculadas a clientes potenciales, clientes y proveedores que&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> recopile mediante los distintos formularios de contacto y/o recogida de información se conservarán mientras no se solicite su supresión por el interesado. Los datos proporcionados por nuestros clientes y proveedores se conservarán mientras se mantenga la relación mercantil entre las partes respetando en cualquier caso los plazos mínimos legales de conservación según la materia.</p>
            <p>En cualquier caso&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> guardará sus datos personales durante el período de tiempo que sea razonablemente necesario teniendo en cuenta nuestras necesidades de dar respuesta a cuestiones que se planteen o resolver problemas, realizar mejoras, activar nuevos servicios y cumplir los requisitos que exija la legislación aplicable. Esto significa que podemos conservar sus datos personales durante un período de tiempo razonable incluso después de que haya dejado de usar nuestros productos o de que haya dejado de usar esta página web. Después de este período, sus datos personales serán eliminados de todos los sistemas de&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong></p>
            <ol start="5">
              <li><strong> ¿Cuál es la base de legitimación para el tratamiento de sus datos?</strong></li>
            </ol>
            <p>Según tipo de tratamiento de datos le resumimos a continuación la base de legitimación de dicho tratamiento:</p>

            <div className={this.tableStyles} style={{ display: 'block', overflowX: 'auto' }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <th align="left"><strong>TRATAMIENTO</strong></th>
                    <th align="left"><strong>BASE DE LEGITIMACIÓN</strong></th>
                  </tr>
                  <tr>
                    <th align="left"><strong>Gestión Contable: </strong>gestión de facturación con clientes y/o proveedores</th>
                    <th align="left">Mantenimiento, desarrollo y control de la relación contractual entre las partes</th>
                  </tr>
                  <tr>
                    <th align="left"><strong>Gestión fiscal: </strong>aplicación de retenciones, bonificaciones, etc.</th>
                    <th align="left">Mantenimiento, desarrollo y control de la relación contractual entre las partes; Cumplimiento obligaciones legales</th>
                  </tr>
                  <tr>
                    <th align="left"><strong>Gestión administrativa: </strong>gestión de logística, almacén, entregas al cliente, recepción de mercancías, etc.</th>
                    <th align="left">Mantenimiento, desarrollo y control de la relación contractual entre las partes</th>
                  </tr>
                  <tr>
                    <th align="left"><strong>Marketing: </strong>Acciones comerciales sobre nuestros productos o servicios dirigidas a nuestros clientes o aquellas personas que nos han solicitado información relativa en el pasado incluyendo la realización de encuestas de satisfacción a nuestros clientes.</th>
                    <th align="left">Consentimiento libre e inequívoco del propio interesado (clientes potenciales), le hacemos constar que la retirada de este consentimiento en ningún caso puede condicionar la ejecución del contrato que hubiera entre las partes; Interés legítimo de la compañía sobre la promoción y comercialización de productos o servicios similares a los obtenidos o solicitados por las personas interesadas en el pasado.</th>
                  </tr>
                  <tr>
                    <th align="left"><strong>Tratamiento de gestión de operaciones con contactos/ usuarios web</strong></th>
                    <th align="left">Consentimiento libre e inequívoco del contacto/usuario web.</th>
                  </tr>
                  <tr>
                    <th align="left"><strong>Tratamiento de gestión de asuntos/expedientes jurídicos</strong></th>
                    <th align="left">Interés legítimo del empresario en el desarrollo de su actividad empresarial; Obligaciones regulatorias</th>
                  </tr>
                  <tr>
                    <th align="left"><strong>Tratamiento en operaciones en fase judicial (civil, administrativos y/o penales) Expedientes judiciales</strong></th>
                    <th align="left">Interés legítimo del empresario en el desarrollo de su actividad empresarial; Obligaciones regulatorias</th>
                  </tr>
                  <tr>
                    <th align="left"><strong>Tratamiento de gestión de actos societarios</strong></th>
                    <th align="left">Interés legítimo del empresario en el desarrollo de su actividad empresarial; Obligaciones regulatorias</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>En cuanto, a la base de legitimación referenciada Usted se encuentra obligado a facilitar los datos personales, en el supuesto de que no facilite sus datos de carácter personal no se podrá ejecutar su contrato, cumplir las obligaciones legales o derivadas de los poderes públicos.</p>
            <ol start="6">
              <li><strong> ¿A qué destinatarios se comunicarán sus datos?</strong></li>
            </ol>
            <p><strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> nunca compartirá sus datos personales con ninguna empresa tercera que pretenda utilizarlos en sus acciones de marketing directo, excepto en el caso de que nos haya autorizado expresamente a ello.</p>
            <p>Le informamos que podemos facilitar sus datos personales a organismos de la Administración Pública y Autoridades competentes en aquellos casos que&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> reciba un requerimiento legal por parte de dichas Autoridades o en los casos que actuando de buena fe, consideramos que tal acción es razonablemente necesaria para cumplir con un proceso judicial; para contestar cualquier reclamación o demanda jurídica; o para proteger los derechos de&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong>&nbsp;o sus clientes y el público en general.</p>
            <p><strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> puede proporcionar sus datos personales a terceras personas (Ej. proveedores de servicios de Internet que nos ayudan a administrar nuestra página web o llevar a cabo los servicios contratados, empresas de soporte y mantenimiento informático, empresas de logística, gestorías y asesoramiento fiscal y contable, etc.). En cualquier caso, estas terceras personas deben mantener, en todo momento, los mismos niveles de seguridad que&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> en relación a sus datos personales y, cuando sea necesario, estarán vinculadas por compromisos legales a fin de guardar sus datos personales de forma privada y segura, y a fin de utilizar únicamente la información siguiendo instrucciones específicas de&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong></p>
            <ol start="7">
              <li><strong> ¿Cuáles son sus derechos como afectado o interesado?</strong></li>
            </ol>
            <p>Cualquier persona tiene derecho a obtener confirmación sobre si&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> está tratando datos personales que le conciernan, o no.</p>
            <p>En concreto, las personas interesadas pueden solicitar el&nbsp;<strong>derecho de acceso</strong>&nbsp;a sus datos personales, así como recibirlos en un formato común y lectura mecanizada si el tratamiento se efectúa por medios electrónicos (<strong>derecho de portabilidad</strong>).</p>
            <p>Asimismo, las personas interesadas pueden solicitar el&nbsp;<strong>derecho de rectificación</strong>&nbsp;de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
            <p>Complementariamente, en determinadas circunstancias, los interesados podrán solicitar la&nbsp;<strong>limitación del tratamiento de sus datos</strong>, o en determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán ejercitar su&nbsp;<strong>derecho a oponerse</strong>&nbsp;al tratamiento de sus datos.&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones o en aquellas excepciones establecidas en la normativa aplicable.<br />
              Asimismo, le informamos que tiene derecho a&nbsp;<strong>retirar sus consentimientos</strong>&nbsp;otorgados en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
            <p>Así mismo se informa al Usuario que en cualquier momento puede ejercitar los mencionados derechos dirigiéndose por escrito a nosotros utilizando los datos de contacto que aparecen en el Apartado 1, ‘Responsable de tratamiento’ de la presente política de Protección de Datos de&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong>, adjuntando copia de su DNI. Usted también tendrá el derecho a presentar una reclamación ante la Agencia Española de Protección de Datos, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos.</p>
            <p><strong>Agencia Española de Protección de Datos.</strong><br />
              <strong>C/ Jorge Juan, 6</strong><br />
              <strong>28001 – Madrid</strong><br />
              <strong>Telf. 901100099 / 912663517</strong></p>
            <ol start="8">
              <li><strong> Protección de datos de los usuarios de la página web.</strong></li>
            </ol>
            <p>De conformidad con el vigente Reglamento (UE) 2016/679,&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> informa que los datos de carácter personal de los Usuarios del sitio web se tratarán para la actividad del tratamiento indicado en cada formulario de recogida de datos de nuestra página web por parte de&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> dicho tratamiento de sus datos estará amparado en su propio consentimiento. Al pulsar el botón “ENVIAR”, el Usuario consiente el tratamiento de sus datos por parte de&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong></p>
            <p>Asimismo, le informamos que salvo obligación legal o consentimiento expreso por su parte&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> no va a ceder sus datos a terceras personas.</p>
            <p>Igualmente, se informa al Usuario que en cualquier momento puede ejercitar los derechos de acceso, rectificación o supresión de datos así como disponer de otros derechos reconocidos en el presente documento y regulados en el Reglamento (UE) 2016/679, notificándolo a&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong><strong>,</strong>&nbsp;Avda. Los Castros S/N CDTUC Fase A Mod. 1, 39005 Santander (Cantabria), tel. 942760866, e-mail:&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong>&nbsp;<a href="mailto:zitelia@zitelia.com">zitelia@zitelia.com</a></p>
            <p>Nuestra política con respecto al correo electrónico se centra en remitir únicamente comunicaciones que usted haya solicitado recibir. Si prefiere no recibir estos mensajes por correo electrónico le ofreceremos a través de los mismos la posibilidad de ejercer su derecho de cancelación y renuncia a la recepción de estos mensajes, en conformidad con lo dispuesto en el TITULO III, Art. 22 de la Ley 34/2002 de Servicios para la Sociedad de la Información y de Comercio Electrónico (LSSICE).</p>
            <ol start="9">
              <li><strong> Otra información de interés sobre nuestra política de protección de datos</strong></li>
            </ol>
            <p><strong>9.1 Medidas de Seguridad</strong></p>
            <p><strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong> adopta los niveles de seguridad requeridos por la normativa Europea y Española vigente en protección de datos teniendo en cuenta el estado de la técnica, los costes de aplicación y la naturaleza, el alcance, el contexto y los fines del tratamiento descritos, así como los riesgos de probabilidad y gravedad variables para sus derechos y libertades como persona.</p>
            <p><strong>9.2 Modificaciones de nuestra Política de Protección de Datos</strong></p>
            <p>Ocasionalmente,&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L. </strong>podrá realizar modificaciones y correcciones en este apartado de Política de Protección de Datos. Por favor, verifique este apartado regularmente para consultar los cambios que puedan haber existido y de qué manera le pueden afectar.</p>
            <p><strong>9.3 ¿Por qué es necesario aceptar está Política de Protección de Datos?</strong></p>
            <p>Este apartado de Política de Protección de Datos le proporciona de un modo fácilmente accesible toda la información necesaria para que puedas conocer la tipología de datos que&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L. </strong>mantiene sobre sus clientes potenciales, clientes y/o proveedores, las finalidades perseguidas, los derechos que la normativa de protección de datos reconoce a Ud. como persona afectada y el modo de cómo ejercer dichos derechos. Por lo tanto, con el envío deliberado de sus datos personales mediante nuestros medios de contacto y/o con el inicio de la relación mercantil con nuestra empresa consideramos que reconoce y acepta el tratamiento de sus datos personales tal y como se describe en la presente política. Esta información personal solamente se usará para los fines para los cuales nos la ha facilitado o determinadas normativas nacionales o regionales nos habilitan hacerlo.</p>
            <p>En cualquier caso, debemos advertirle que una negativa por su parte para facilitarnos determinados datos solicitados podría obstaculizar el desarrollo de la relación contractual entre las partes con posibles consecuencias al momento de prestar las diversas prestaciones contempladas dentro del contrato mercantil celebrado con la parte contratante.</p>
            <p>Si tiene cualquier pregunta en relación a este apartado de Política de Protección de Datos de&nbsp;<strong>ZITELIA SOLUCIONES TECNOLOGICAS S.L.L.</strong>, por favor, póngase en contacto con la empresa usando la dirección facilitada en la Apartado primero “Responsable de Tratamiento” y estaremos encantados de atenderle y dar respuesta a las cuestiones adicionales que nos quiera plantear.</p>
            <ol start="10">
              <li><strong> Legislación aplicable</strong></li>
            </ol>
            <p>Las presentes Condiciones se regirán en todo momento por lo dispuesto en la legislación española y europea en materia de protección de datos de carácter personal y de privacidad.</p>


          </section>

        </Segment>
      </main>
    )

  }
}

export default ScreensPrivacyPolicy
