import React, { PureComponent } from 'react'

import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { css } from 'emotion'

import DocumentTitle from '../../components/DocumentTitle'
import Logo from '../../components/Logo'
import { Space } from '../../components/UI'

class ScreensLegalWarning extends PureComponent {


  mainStyles = css({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    //height: '100%',
  })
  loginStyles = css({
    width: '90%',
    padding: '2rem !important',
    animation: 'fadeInOpacity 1s ease 0s 1',
    '@media (min-width: 1280px)': {
      padding: '6rem !important',
      width: '1200px',
    },
    
  })
  logoStyles = css({
    maxWidth: '400px',
    width: '60%',
    '@media (min-width: 576px)': {
      width: '180px',
    },
  })

  render() {
    return (
      <main className={this.mainStyles}>
        <DocumentTitle title="Acceso al área de gestión" />
        <Segment className={this.loginStyles}>
          <div style={{width:'100%'}}>
          <div style={{ fill: '#f8b42d', textAlign: 'center', maxWidth:'400px', margin:'auto'}}>
            <Link to={'/'}>
              <Logo className={this.logoStyles} />
            </Link>
          </div>
          </div>
          <Space height="2rem" />
          <h1>INFORMACIÓN LEGAL</h1>
        <br/>
        <p>El titular de esta página web es ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L. con CIF B39776323 que tiene el domicilio social en Avda. de los Castros 33, Urb. Los Chopos, Portal 1 - Bajo 2, 39005, Santander, Cantabria.</p>
        <p>Para mayor información puede contactar con nosotros/as a través del número de teléfono 942 760 866.</p>

        <br/><p><strong>Propiedad intelectual</strong></p>
        <p>Copyright © 2023, ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L. Todos los derechos de explotación están reservados.</p>
        <p>Los derechos de propiedad intelectual sobre la web, diseño gráfico, códigos, e información enviada por e-mail de esta página web son titularidad de ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L.</p>
        <p>Corresponde a dicha empresa el ejercicio exclusivo de los derechos de explotación de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación.</p>
        <br/><p><strong>Actualización de la web</strong></p>
        <p>ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L. se reserva el derecho a actualizar, modificar o eliminar la información contenida en sus páginas web pudiendo incluso limitar o no permitir el acceso a dicha información.</p>
        <p>La información contenida en esta página web es la vigente en la fecha de su última actualización y tiene que ser considerada como una información orientativa para la persona usuaria, relativa a los trámites y servicios y a otras informaciones que contienen estas páginas.</p>
        <br/><p><strong>Responsabilidad de los contenidos de la web</strong></p>
        <p>ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L. rechaza la responsabilidad que se derive de la mala utilización de los contenidos y se reserva el derecho a actualizarlos cuando quiera, a eliminarlos, limitarlos o impedir el acceso a ellos, de manera temporal o definitiva.</p>
        <p>ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L. rechaza la responsabilidad sobre cualquier información no contenida en estas páginas web y, por tanto, no elaborada por ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L. o no publicada con su nombre.</p>
        <p>ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L. no se responsabiliza de las posibles discrepancias que puedan surgir entre la versión de sus documentos impresos y la versión electrónica de los mismos publicados en sus páginas web.</p>
        <br/><p><strong>Responsabilidad por seguridad</strong></p>
        <p>ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L. no se hace responsable de los posibles errores de seguridad que se pudieran producir por el hecho de utilizar versiones de navegadores no actualizadas, o de las consecuencias que se pudieran derivar del mal funcionamiento del navegador, ya sea por configuración inadecuada, presencia de virus informáticos o cualquier otra causa ajena ZITELIA SOLUCIONES TECNOLOGICAS, S.L.L.</p>
        <br/><p><strong>Aceptación de las presentes condiciones</strong></p>
        <p>Al utilizar este portal web, asumimos que la persona usuaria ha leído este documento cuidadosamente y que ha entendido y seguido sus consejos.</p>
        <p>Mediante el acceso y uso de este portal web, la persona usuaria manifiesta su acuerdo de forma expresa y sin reservas a las condiciones e instrucciones publicadas en el momento de hacer efectivo dicho acceso.</p>
        <p>La persona usuaria de este portal web se compromete a hacer un uso adecuado y lícito de los contenidos y servicios que se prestan en él, absteniéndose de realizar actividades ilegales o contrarias a la buena fe y al orden público y de llevar a cabo toda actividad encaminada a suplantar a cualquier persona física o jurídica, o dirigida a interferir, violar, alterar o desconectar el sistema, servidores, redes o contenidos o suprimir, alterar, eludir o manipular cualquiera de los dispositivos de protección o sistemas de seguridad que puedan estar instalados en el portal web.</p>
        <p>En caso de que la persona usuaria no esté de acuerdo con esta política de protección de datos o con los consejos que a través de la misma se dan, por favor, no utilice nuestro portal web. Nos reservamos el derecho a modificar la presente condición de uso cuando consideremos que es necesario. La continuidad por la persona usuaria en el uso de este portal web, después de haber introducido dichos cambios, implicará la total aceptación de los mismos.</p>
        </Segment>
      </main>
    )

  }
}

export default ScreensLegalWarning
