export const APIURL = 'https://api.zitelia.com'
export const APIVERSION = 'v1'
export const APIBASEURL = APIURL+"/"+APIVERSION
export const APIREQUESTOPTIONS = {
  responseType: 'json',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

export const PAGETITLESUFFIX = ' :: ZITELIA'
export const APP_BILL_AUTHOR = '5' // "bill_author_id" -> Debe corresponder con el id de la empresa que expide las facturas y pedidos.
//export const COLOR = '#af1685'
export const COLOR = '#f8b42d' // #faa11e  //#f8b42d
//export const COLOR = '#924d8b'
export const SUICOLOR = 'purple'
export const CHARTCOLORS = [
  '#924d8b',
  '#0088FE',
  '#FFBB28',
  '#00C49F',
  '#FF8042',
  '#aaaaaa',
]

export const MONTHS = {
  '1': { sort: 'Ene', long: 'Enero' },
  '2': { sort: 'Feb', long: 'Febrero' },
  '3': { sort: 'Mar', long: 'Marzo' },
  '4': { sort: 'Abr', long: 'Abril' },
  '5': { sort: 'May', long: 'Mayo' },
  '6': { sort: 'Jun', long: 'Junio' },
  '7': { sort: 'Jul', long: 'Julio' },
  '8': { sort: 'Ago', long: 'Agosto' },
  '9': { sort: 'Sep', long: 'Septiembre' },
  '10': { sort: 'Oct', long: 'Octubre' },
  '11': { sort: 'Nov', long: 'Noviembre' },
  '12': { sort: 'Dic', long: 'Diciembre' },
}

export const PAGESIZE = 100


export const PAYMENT_METHOD = {
  "TPV" : {value: 1, text:"Tarjeta de crédido o debito"},
  "PAYPAL" : {value: 2, text:"Paypal"},
  "CARGO" : {value: 3, text:"Cargo en cuenta"},
  "TRANS" : {value: 4, text:"Transferencia"},
  "CHEQUE" : {value: 5, text:"Cheque bancario"},
  "METALICO" : {value: 6, text:"Metálico"},
  "OTRO" : {value: 7, text:"Sin determinar"},
}