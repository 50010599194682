import React from 'react'
import { Message, Icon } from 'semantic-ui-react'
import { css } from 'emotion'

import { withMessagesContext } from './Context'
import { SpaceBetween } from './UI'

const Notification = ({ header, content, onDismiss, ...rest }) => {
  const { datetime, data } = content
  const notificationStyles = css({
    animation: 'fadeInOpacity 1s ease 0s 1',
    '&:hover': {
      cursor: 'pointer',
      opacity: '.7',
    },
  })
  const deleteStyles = css({
    position: 'absolute !important',
    width: '100%',
    height: '100%',
    transition: 'background .2s ease',
    opacity: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '5000',
    '&:hover': {
      background: 'rgba(0,0,0,.6)',
      cursor: 'pointer',
      opacity: '1',
    },
  })
  return (
    <div style={{ position: 'relative' }}>
      <div className={deleteStyles} onClick={onDismiss}>
        <Icon name="trash" size="big" inverted />
      </div>
      <Message className={notificationStyles} {...rest}>
        <Message.Header>
          <SpaceBetween>
            <div>{header}</div>
            <div
              style={{
                fontSize: '0.9em',
                fontWeight: 'normal',
                fontStyle: 'italic',
                marginRight: '1rem',
              }}
            >
              {datetime.toLocaleString()}
            </div>
          </SpaceBetween>
        </Message.Header>
        {Array.isArray(data) && data.length > 1 ? (
          <Message.List items={data} />
        ) : (
          <p>{Array.isArray(data) ? data[0] : data}</p>
        )}
      </Message>
    </div>
  )
}

const Messages = ({
  globalMessages: { error, warning, success, info, removeMessage },
}) => {
  if (
    error.length === 0 &&
    warning.length === 0 &&
    success.length === 0 &&
    info.length === 0
  )
    return (
      <div style={{ fontStyle: 'italic' }}>No hay mensajes pendientes...</div>
    )
  return (
    <div>
      {error.map((message, i) => (
        <Notification
          key={message.data}
          error
          header="Error"
          content={message}
          onDismiss={() => removeMessage(i, 'error')}
        />
      ))}
      {warning.map((message, i) => (
        <Notification
          key={message.data}
          warning
          header="Advertencia"
          content={message}
          onDismiss={() => removeMessage(i, 'warning')}
        />
      ))}
      {success.map((message, i) => (
        <Notification
          key={message.data}
          success
          header="Completado"
          content={message}
          onDismiss={() => removeMessage(i, 'success')}
        />
      ))}
      {info.map((message, i) => (
        <Notification
          key={message.data}
          info
          header="Información"
          content={message}
          onDismiss={() => removeMessage(i, 'info')}
        />
      ))}
    </div>
  )
}

export default withMessagesContext(Messages)
