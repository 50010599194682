import axios from 'axios'
import { APIBASEURL } from './globals'

export const apiRequest = (type, endpoint, data = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: type,
      url: `${APIBASEURL}${endpoint}`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data,
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getRequest = endpoint => apiRequest('get', endpoint)

export const postRequest = (endpoint, data) =>
  apiRequest('post', endpoint, data)

export const putRequest = (endpoint, data) => apiRequest('put', endpoint, data)

export const deleteRequest = endpoint => apiRequest('delete', endpoint)
