import React from 'react'

export const Phone = ({ phone, separator = ' ', style, ...rest }) => (
  <div
    style={{
      display: 'inline-block',
      whiteSpace: 'nowrap',
      ...style,
    }}
    {...rest}
  >
    {phone.replace(/\B(?=(\d{3})+(?!\d))/g, separator)}
  </div>
)
